import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const Bio = ({ location }) => (
  <Layout location={location}>
    <Seo title='Bio' />
    <section className='bio'>
      <h2>About Me</h2>
      <p style={{ fontSize: '1.2rem', lineHeight: '1.5rem' }}>
        I was born and raised in the Los Angeles area, where I grew up
        bodysurfing on the beaches, skiing the local mountains and hiking the
        back country of Yosemite National Park. I was most interested in
        anything outdoors, art and science.
        <br />
        <br />
        Majoring in Kinesiology at the University of California, I met some
        Christians and was surprised to learn of the great love God has for me.
        Because of this love, I decided to trust my entire life to Jesus Christ.
        I studied the Bible to learn more about God. I found knowing God to be
        so freeing and exciting that I wanted others to know the love I had
        found. So, I joined the staff of Cru (Campus Crusade for Christ) and
        worked on college campuses in Texas and Southern California.
        <br />
        <br />
        In 1979 I married my husband, Vance, who also worked with Cru. We
        desired to go somewhere where people had less opportunity to hear about
        Jesus Christ. We spent ten years in Egypt where our three children were
        born. Then, we spent four years in Moscow, Russia and saw the fall of
        Communism. We also spent some time in other countries including the
        Philippines, Japan and England.
        <br />
        <br />
        In 1996, we moved to San Clemente, California to work with the Jesus
        Film Project. Currently we help distribute this film which was
        originally released through Warner Brothers, has been translated into
        more than eighteen hundred languages and seen by over six billion
        viewers.
        <br />
        <br />I have been painting for about fifteen years. I started with
        watercolor, then tried pastel and finally oils. I'm astounded by the
        beauty of the Pacific Ocean and the bluffs along the coast. Painting
        outdoors is my favorite way to paint as I try to capture a little of
        this incredible beauty. Painting, like music, can be a form of worship
        for the Master Painter whose works I see in the ever changing sea and
        sky.
      </p>
    </section>
  </Layout>
);

export default Bio;
